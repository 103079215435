.credit-content {
  background-color: rgb(248, 248, 248);

  .table-header {
    display: flex;
    width: 100%;
    height: 40px;
    background-color: #fff;

    .field {
      flex: 1;
      font-size: 12px;
      justify-content: center;
      color: rgb(124, 123, 129);
    }

    .field1 {
      justify-content: center;
      max-width: 30px;
    }

    .field2 {
      min-width: 120px;
    }

    .field3 {
      max-width: 30px;
    }

    .field4 {}

    .field5 {}

    .field6 {
      max-width: 30px;
    }
  }

  .table-content {
    background-color: #fff;
    width: 100%;
    font-size: 12px;
    height: 40px;
    overflow: hidden;
    display: table;

    .item {
      display: flex;
      width: 100%;
      height: 40px;
    }

    .field {
      font-size: 12px;
      flex: 1;
    }

    .field1 {
      max-width: 30px;
    }

    .field2 {
      min-width: 120px;
      justify-content: left;
    }

    .field3 {
      max-width: 30px;
    }

    .field4 {}

    .field6 {
      max-width: 30px;
    }
  }

  .rule-content {

    overflow: hidden;
    width: 95%;
    font-size: 12px;
    margin: 10px auto;
  }

  .field {
    display: flex;
    justify-content: center;
    align-items: center;

    .team-logo {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background-size: cover;
    }
  }
}