.table-header {
  display: flex;
  width: 100%;
  height: 40px;

  .field {
    flex: 1;
    font-size: 13px;
    justify-content: center
  }

  .field1 {
    justify-content: center
  }

  .field2 {
    justify-content: right
  }

  .field3 {}

  .field4 {
    justify-content: left
  }
}

.table-content {
  display: table;
  width: 100%;
  font-size: 13px;
  height: 40px;

  .item {
    display: flex;
    width: 100%;
    height: 40px;



    .next-title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .field {
    font-size: 12px;
    flex: 1;
  }

  .field1 {}

  .field2 {
    justify-content: right
  }

  .field3 {}

  .field4 {
    justify-content: left
  }
}

.field {
  display: flex;
  justify-content: center;
  align-items: center;

  .team-logo {
    width: 20px;
    height: 20px;
    margin-right: 3px;
    margin-left: 3px;
    background-size: cover;
  }
}

.course-team {
  min-width: 120px;
}

.adm-modal-content {
  .course-info {
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;

  }
}