.team-scrum-table-header {
  display: flex;
  width: 100%;
  height: 30px;


  .rank {
    color: #999999;
    margin-left: 5px;
    font-size: 13px;
  }

  .field {
    flex: 1;
    font-size: 13px;
    justify-content: center
  }

  .team-name {
    font-size: 16px;
  }


  .field1 {
    justify-content: center;
    max-width: 70px;
  }

  .field2 {
    justify-content: center !important;
    margin-left: 5px;
  }

  .field3 {
    justify-content: center;
    margin-right: 10px;
    max-width: 60px;
  }

  .field4 {
    justify-content: left
  }
}

.team-scrum-table-content {
  width: 100%;
  font-size: 13px;

  .item {
    display: flex;
    width: 100%;
    height: 40px;

  }

  .field {
    font-size: 13px;
    flex: 1;
  }

  .field1 {
    max-width: 70px;
  }

  .field2 {
    justify-content: center;
    display: flex;
    margin-left: 5px;
  }

  .field3 {
    justify-content: center;
    margin-right: 10px;
    max-width: 60px;
  }

  .field4 {
    justify-content: left
  }
}

.field {
  display: flex;
  justify-content: center;
  align-items: center;

  .team-logo {
    width: 20px;
    max-width: 20px;
    height: 20px;
    background-size: cover;
  }

  .team-info {
    flex: 1;
    font-size: 13px;

    .scrum-name {
      font-size: 13px;
    }

    .team-name {
      color: #999999;
      margin-top: 2px;
    }

    p {
      line-height: 15px;
      margin: 0;
      padding: 0;
      text-align: left;
      padding-left: 5px;
    }
  }
}