:root:root {
  --adm-color-primary: rgb(189, 45, 42);
}

html {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

.App {
  text-align: center;
  width: 100%;

  .banner {
    width: 100%;

    .image {
      width: 100%;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  display: flex;
  width: 100%;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--adm-color-background);
}



.season-block {
  min-width: 80px;
  width: 80px;
  max-width: 80px;
  line-height: 39px;
  font-size: 15px;
  text-align: center;

  flex: 1;

}

.transverse-nav {
  flex: 1;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}